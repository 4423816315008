$ring: 12px;
$planetSize: 10rem;
$ringWidth: 13rem;

.space-container {
  position: relative;
  padding: 10px;

  .planet-container {
    width: $planetSize;
    height: $planetSize;
    padding: 20px;
    border-radius: 100%;
    background: linear-gradient(150deg, $accentLight, transparent, transparent);
    position: relative;

    .planet-ring {
      width: $ringWidth;
      height: 2rem;
      top: 72px;
      left: -15px;
      position: absolute;
      border-top: $ring solid transparent;
      border-bottom: $ring solid $white;
      border-left: $ring solid $white;
      border-right: $ring solid $white;
      
      transform: rotate(-20deg);
      
      border-radius: 100%;
    }

    .planet-ring2 {
      @extend .planet-ring;
      border-top: $ring solid $white;
      z-index: -1;
    }
    .planet {
      width: $planetSize;
      height: $planetSize;
      background: linear-gradient(150deg, $accent, #c9548e, transparent);
      border-radius: 100%;
    }
  }

  .stars-container {
    position: relative;
    width: 50%;
    height: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .stars {
      width: 170px;
      top: 13rem;
      position: absolute;
      left: 8rem;
      div:nth-child(1) {
        width: 20px;
        position: absolute;
        height: 6px;
        border-radius: 100%;
        background: #fff;
        animation: blink-width 1s ease infinite;
      }
      div:nth-child(2) {
        width: 6px;
        height: 20px;
        top: -5px;
        position: absolute;
        background: #fff;
        border-radius: 100%;
        left: 7px;
        top: -7px;
        transform-origin: middle;
        animation: blink-height 1s ease infinite;
      }

      div:nth-child(3) {
        background: #fff;
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        left: 5px;
        top: -2px;
        border-radius: 100px;
      }
    }

    .stars:nth-child(2) {
      position: relative;
      transform: scale(0.9);
      top: -1rem;
      left: 6rem;
      div {
        animation-delay: 0.5s;
      }
    }
    .stars:nth-child(3) {
      position: relative;
      transform: scale(0.6);
      top: 20px;
      left: 170px;
      div {
        animation-delay: 0.5s;
      }
    }
    .stars:nth-child(4) {
      position: relative;
      transform: scale(0.8);
      top: -3rem;
      left: 26rem;
      div {
        animation-delay: 0.8s;
      }
    }
    .stars:nth-child(5) {
      position: relative;
      transform: scale(0.5);
      top: 0;
      left: 33rem;
      div {
        animation-delay: 0.7s;
      }
    }
    .stars:nth-child(6) {
      position: relative;
      transform: scale(1);
      top: 10rem;
      left: 35rem;
      div {
        animation-delay: 1s;
      }
    }
    .stars:nth-child(7) {
      position: relative;
      transform: scale(0.5);
      top: 13rem;
      left: 25rem;
      div {
        animation-delay: 1s;
      }
    }
  }
}
@mixin keyframes($animationName) {
  @-webkit-keyframes #{"$animationName"} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@include keyframes(blink-height) {
  0% {
    height: 20px;
    top: -7px;
  }
  50% {
    height: 10px;
    top: -3px;
  }
  100% {
    height: 20px;
    top: -7px;
  }
}

@include keyframes(blink-width) {
  0% {
    width: 20px;
    left: 0px;
  }
  50% {
    width: 10px;
    left: 5px;
  }
  100% {
    width: 20px;
    left: 0px;
  }
}
