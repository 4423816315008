@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@400;500;700&display=swap");

$background: #2b2451;
$accent: #f05672;
$accentLight: #f48599;
$accentLighter: #f8b4c0;
$offwhite: #e6e6e6;
$white: #ffffff;

@import "./planet.scss";

.background-image {
  background: radial-gradient(
    circle,
    #2b2451,
    #251f47,
    #1f1a3e,
    #1a1634,
    #15112b
  );
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.App {
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    height: calc(100vh - 100px);
    overflow: hidden;
  }
}
